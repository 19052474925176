import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePostAsync, handlePutAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";

var responseModel = new ResponseModel();

export class LiquorStoreSalesService {
    static async getLiquorStoreService(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FETCH_LIQUOR_STORE}`; // Endpoint 
        const requestModel = { storeId, searchText };

        try {
            responseModel = await handleGetAsync(requestURL, true, requestModel);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to fetch liquor store data.');
            }
        } catch (error) {
            console.error('Error fetching liquor store data:', error);
            throw error;
        }
    }

    // Get by ID
    static async getItemById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.LIQUOR_STORE}/${id}`;// Endpoint 

        try {
            responseModel = await handleGetAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Error fetching liquor store item details.');
            }
        } catch (error) {
            console.error('Error fetching item details:', error);
            throw error;
        }
    }

    // Update
    static async updateLiquorStoreService(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.LIQUOR_STORE}/${id}`;// Endpoint 

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update liquor store data.');
        }
    }

    // Create
    static async createLiquorStoreService(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.LIQUOR_STORE; // Endpoint 

        responseModel = await handlePostAsync(requestURL, true, data);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel && responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to create new liquor store data.');
        }
    }

    // Delete single by ID
    static async deleteLiquorStoreService(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.LIQUOR_STORE}/${id}`; // Endpoint 

        try {
            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                return responseModel.data;
            } else {
                throw new Error('Failed to delete liquor store data.');
            }
        } catch (error) {
            console.error('Error deleting liquor store data:', error);
            throw error;
        }
    }

    // Delete many
    static async deleteManyLiquorStoreService(ids) {
        responseModel = new ResponseModel();
        console.log('Response Model:', responseModel);

        const requestURL = `${EndPoint.LIQUOR_STORE}/deletemany`;

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            const errorMessage = responseModel ? responseModel.errormessage : 'An unknown error occurred.';
            handleNotify(errorMessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
        
    }
}
