import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate, useLocation } from "react-router";
import { LiquorStoreSalesService } from "../../../services/user/liquorstoresales.services";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../common/notification/toaster_notify.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AuthGuard from "../../common/authguard.component";

const AddLiquorStoreSalesComponent = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageNumber = location.state?.pageNumber || 0;
    const liquorSalesId = location.state?.liquorSalesId || 0;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const isEditMode = Boolean(liquorSalesId);

    useEffect(() => {
        if (isEditMode) {
            const fetchItemDetails = async () => {
                try {
                    await LiquorStoreSalesService.getItemById(liquorSalesId).then(response => {
                        if (response) {
                            formik.setValues({
                                ...response,
                                invoiceDate: moment(response?.invoiceDate).format('YYYY-MM-DD')
                            });
                        }
                    });
                } catch (error) {
                    // console.error('Error fetching item details:', error);
                }
            };
            fetchItemDetails();
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            liquorSalesId: 0,
            // invoiceDate: moment(new Date()).format('YYYY-MM-DD'),
            tobacco: 0,
            beer: 0,
            spirits: 0,
            wine: 0,
            nonAlcoholic: 0,
            barSales: 0,
            lotto: 0,
            scratchOff: 0,
            salesTaxPackage: 0,
            salesTaxBar: 0,
            totalSalesTax: 0,
            barCreditDebit: 0,
            packageCreditDebit: 0,
            check: 0,
            payOut: 0,
            coupons: 0,
            lottoPaidOut: 0,
            houseAccounts: 0,
            refunds: 0,
            driveOff: 0,
            cashDeposit: 0,
            actualDeposit: 0,
            overShort: 0,
            storeId: selectedStore,
        },
        validationSchema: Yup.object({
            // invoiceDate: Yup.date().required('Date is Required').typeError('Invalid Date format'),
        }),
        onSubmit: async (values) => {
            try {
                assignFloatValues(values);
                if (isEditMode) {
                    await LiquorStoreSalesService.updateLiquorStoreService(values.liquorSalesId, values);
                    navigate('/salesinfo/liquorstoresales', { state: { pageNumber: pageNumber } });
                } else {
                    await LiquorStoreSalesService.createLiquorStoreService(values)
                    if (isInWizard) {
                        onNext();
                    } else {
                        navigate('/salesinfo/liquorstoresales', { state: { pageNumber: pageNumber } });
                    }
                }
            } catch (error) {
                handleNotify('An error occurred while saving the liquor sales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    const assignFloatValues = (values) => {
        values.tobacco = parseFloat(values.tobacco) || 0;
        values.beer = parseFloat(values.beer) || 0;
        values.spirits = parseFloat(values.spirits) || 0;
        values.wine = parseFloat(values.wine) || 0;
        values.nonAlcoholic = parseFloat(values.nonAlcoholic) || 0;
        values.barSales = parseFloat(values.barSales) || 0;
        values.lotto = parseFloat(values.lotto) || 0;
        values.scratchOff = parseFloat(values.scratchOff) || 0;
        values.salesTaxPackage = parseFloat(values.salesTaxPackage) || 0;
        values.salesTaxBar = parseFloat(values.salesTaxBar) || 0;
        values.totalSalesTax = parseFloat(values.totalSalesTax) || 0;
        values.barCreditDebit = parseFloat(values.barCreditDebit) || 0;
        values.packageCreditDebit = parseFloat(values.packageCreditDebit) || 0;
        values.check = parseFloat(values.check) || 0;
        values.payOut = parseFloat(values.payOut) || 0;
        values.coupons = parseFloat(values.coupons) || 0;
        values.lottoPaidOut = parseFloat(values.lottoPaidOut) || 0;
        values.houseAccounts = parseFloat(values.houseAccounts) || 0;
        values.refunds = parseFloat(values.refunds) || 0;
        values.driveOff = parseFloat(values.driveOff) || 0;
        values.cashDeposit = parseFloat(values.cashDeposit) || 0;
        values.actualDeposit = parseFloat(values.actualDeposit) || 0;
        values.overShort = parseFloat(values.overShort) || 0;
    };

    const handleCancelClick = () => {
        navigate('/salesinfo/liquorstoresales', { state: { pageNumber: pageNumber } });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);

        const updatedValues = { ...formik.values, [name]: value };

        //Total Sales Tax total
        const calculateTotal = (updatedValues) => {
            return (
                parseFloat(updatedValues.tobacco || 0) +
                parseFloat(updatedValues.beer || 0) +
                parseFloat(updatedValues.spirits || 0) +
                parseFloat(updatedValues.wine || 0) +
                parseFloat(updatedValues.nonAlcoholic || 0) +
                parseFloat(updatedValues.barSales || 0) +
                parseFloat(updatedValues.lotto || 0) +
                parseFloat(updatedValues.scratchOff || 0) +
                parseFloat(updatedValues.salesTaxPackage || 0) +
                parseFloat(updatedValues.salesTaxBar || 0) +
                parseFloat(updatedValues.barCreditDebit || 0) +
                parseFloat(updatedValues.packageCreditDebit || 0) +
                parseFloat(updatedValues.check || 0) +
                parseFloat(updatedValues.payOut || 0) +
                parseFloat(updatedValues.coupons || 0) +
                parseFloat(updatedValues.lottoPaidOut || 0) +
                parseFloat(updatedValues.houseAccounts || 0) +
                parseFloat(updatedValues.refunds || 0) +
                parseFloat(updatedValues.driveOff || 0) +
                parseFloat(updatedValues.cashDeposit || 0) +
                parseFloat(updatedValues.actualDeposit || 0)

            )
        }

        //actualDeposit - cashDeposit (-subtraction)
        const calculateOverShortTotal = (updatedValues) => {
            return (
                parseFloat(updatedValues.actualDeposit || 0) -
                parseFloat(updatedValues.cashDeposit || 0)
            )
        }

        const totalSalesTax = calculateTotal(updatedValues)
        const overShort = calculateOverShortTotal(updatedValues)

        formik.setFieldValue('totalSalesTax', totalSalesTax)
        formik.setFieldValue('overShort', overShort);
    }


    return (
        <AuthGuard page="allowDailySales">
            <div className={showWrapper ? "page-wrapper" : ''}>
                <div className="page-content">
                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Liquor Store Sales</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {isEditMode ? 'Edit Liquor Store Sales' : 'Add Liquor Store Sales'}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Liquor Store Sales' : 'Add Liquor Store Sales'}</div>
                            </div>
                        </>
                    )}
                    {!isInWizard && (<hr />)}

                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <div className="card">
                                <div className="card-body p-4">
                                    <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit} noValidate>
                                        <div className="col-md-12">
                                            <div className="row g-3">
                                                <div className="d-flex col-md-12">
                                                    {/* <div className="col-md-3">
                                                        <label htmlFor="invoiceDate" className="form-label">Date:<span className="text-danger">*</span></label>
                                                        <DatePicker
                                                            selected={formik.values.invoiceDate}
                                                            onChange={val => {
                                                                formik.setFieldValue("invoiceDate", val);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            name="invoiceDate"
                                                            dateFormat="MM/dd/YYYY"
                                                            className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                                            id="invoiceDate"
                                                        />
                                                        {formik.touched.invoiceDate && formik.errors.invoiceDate ? (
                                                            <div className='text-danger'>{formik.errors.invoiceDate}</div>
                                                        ) : null}
                                                    </div> */}
                                                </div>
                                                {/* Adding fields as per your request */}
                                                <div className="col-md-6">
                                                    <label htmlFor="tobacco" className="form-label">Tobacco:</label>
                                                    <input
                                                        name="tobacco"
                                                        type="number"
                                                        value={formik.values.tobacco}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="tobacco"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="barCreditDebit" className="form-label">BAR Credit/Debit:</label>
                                                    <input
                                                        name="barCreditDebit"
                                                        type="number"
                                                        value={formik.values.barCreditDebit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="barCreditDebit"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="beer" className="form-label">Beer:</label>
                                                    <input
                                                        name="beer"
                                                        type="number"
                                                        value={formik.values.beer}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="beer"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="packageCreditDebit" className="form-label">Package Credit/Debit:</label>
                                                    <input
                                                        name="packageCreditDebit"
                                                        type="number"
                                                        value={formik.values.packageCreditDebit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="packageCreditDebit"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="spirits" className="form-label">Spirits:</label>
                                                    <input
                                                        name="spirits"
                                                        type="number"
                                                        value={formik.values.spirits}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="spirits"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="check" className="form-label">Check:</label>
                                                    <input
                                                        name="check"
                                                        type="number"
                                                        value={formik.values.check}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="check"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="wine" className="form-label">Wine:</label>
                                                    <input
                                                        name="wine"
                                                        type="number"
                                                        value={formik.values.wine}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="wine"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="payOut" className="form-label">Pay Out:</label>
                                                    <input
                                                        name="payOut"
                                                        type="number"
                                                        value={formik.values.payOut}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="payOut"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nonAlcoholic" className="form-label">Non-Alcoholic:</label>
                                                    <input
                                                        name="nonAlcoholic"
                                                        type="number"
                                                        value={formik.values.nonAlcoholic}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="nonAlcoholic"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="coupons" className="form-label">Coupons:</label>
                                                    <input
                                                        name="coupons"
                                                        type="number"
                                                        value={formik.values.coupons}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="coupons"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="barSales" className="form-label">BAR Sales:</label>
                                                    <input
                                                        name="barSales"
                                                        type="number"
                                                        value={formik.values.barSales}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="barSales"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="lottoPaidOut" className="form-label">Lotto Paid Out:</label>
                                                    <input
                                                        name="lottoPaidOut"
                                                        type="number"
                                                        value={formik.values.lottoPaidOut}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="lottoPaidOut"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="lotto" className="form-label">Lotto:</label>
                                                    <input
                                                        name="lotto"
                                                        type="number"
                                                        value={formik.values.lotto}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="lotto"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="houseAccounts" className="form-label">House Accounts:</label>
                                                    <input
                                                        name="houseAccounts"
                                                        type="number"
                                                        value={formik.values.houseAccounts}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="houseAccounts"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="scratchOff" className="form-label">Scratch Off:</label>
                                                    <input
                                                        name="scratchOff"
                                                        type="number"
                                                        value={formik.values.scratchOff}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="scratchOff"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="refunds" className="form-label">Refunds:</label>
                                                    <input
                                                        name="refunds"
                                                        type="number"
                                                        value={formik.values.refunds}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="refunds"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="salesTaxPackage" className="form-label">Sales Tax Package:</label>
                                                    <input
                                                        name="salesTaxPackage"
                                                        type="number"
                                                        value={formik.values.salesTaxPackage}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="salesTaxPackage"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="driveOff" className="form-label">Drive-Off:</label>
                                                    <input
                                                        name="driveOff"
                                                        type="number"
                                                        value={formik.values.driveOff}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="driveOff"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="salesTaxBar" className="form-label">Sales Tax BAR:</label>
                                                    <input
                                                        name="salesTaxBar"
                                                        type="number"
                                                        value={formik.values.salesTaxBar}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="salesTaxBar"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="actualDeposit" className="form-label">Actual Deposit:</label>
                                                    <input
                                                        name="actualDeposit"
                                                        type="number"
                                                        value={formik.values.actualDeposit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="actualDeposit"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="totalSalesTax" className="form-label">Total Sales Tax:</label>
                                                    <input
                                                        name="totalSalesTax"
                                                        type="number"
                                                        value={formik.values.totalSalesTax}
                                                        // onChange={handleChange}
                                                        // onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="totalSalesTax"
                                                        placeholder="0"
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="cashDeposit" className="form-label">Cash Deposit:</label>
                                                    <input
                                                        name="cashDeposit"
                                                        type="number"
                                                        value={formik.values.cashDeposit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="cashDeposit"
                                                        placeholder="0"

                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="overShort" className="form-label">Over/Short:</label>
                                                    <input
                                                        name="overShort"
                                                        type="number"
                                                        value={formik.values.overShort}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="overShort"
                                                        placeholder="0"
                                                        readOnly
                                                    />
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <label htmlFor="notes" className="form-label">Notes:</label>
                                                    <textarea
                                                        name="notes"
                                                        value={formik.values.notes}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="notes"
                                                        rows="3"
                                                    />
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-5">
                                            <div className="d-md-flex d-grid align-items-center gap-3">
                                                {showButtons && (
                                                    <>
                                                        {isInWizard ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary me-2">Save & Next</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onBack}>Back</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>Skip</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={() => navigate('/dashboard')}>Finish</button>
                                                            </>
                                                        ) : (
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-primary px-4 me-3">
                                                                    {isEditMode ? 'Update' : 'Save'}
                                                                </button>
                                                                <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 mt-5">
                                            <button type="submit" className="btn btn-primary px-4 me-3" onClick={onNext}>
                                                {isEditMode ? 'Update' : 'Save'}
                                            </button>
                                            <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                Cancel
                                            </button>
                                        </div> */}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthGuard>
    );
};

export default AddLiquorStoreSalesComponent;
