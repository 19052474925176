import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate, useLocation } from "react-router";
import { DailySalesService } from "../../../services/user/dailysales.services";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../common/notification/toaster_notify.component";
import AuthGuard from "../../common/authguard.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddDailySalesComponent = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageNumber = location.state?.pageNumber || 0;
    const [item, setItem] = useState(location.state?.dailySalesId || null);
    const dailySalesId = location.state?.dailySalesId || 0;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const isEditMode = Boolean(dailySalesId);

    useEffect(() => {
        if (isEditMode) {
            const fetchItemDetails = async () => {
                try {
                    await DailySalesService.getItemById(dailySalesId).then(response => {
                        if (response) {
                            formik.setValues({
                                ...response,
                                invoiceDate: moment(response?.invoiceDate).format('YYYY-MM-DD'),
                                inHouse: response.inHouse || '0.00',
                            });
                        }
                    });
                } catch (error) {
                    // console.error('Error fetching item details:', error);
                }
            };
            fetchItemDetails();
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            dailySalesId: 0,
            invoiceDate: moment(new Date()).format('MM/DD/YYYY'),
            fuel: 0,
            credit: 0,
            cigarette: 0,
            debit: 0,
            beer: 0,
            fuelman: 0,
            taxGrocery: 0,
            inHouse: 0,
            check: 0,
            noTax: 0,
            ebt: 0,
            deli: 0,
            lottoPay: 0,
            phoneCards: 0,
            payOut: 0,
            lotto: 0,
            coupons: 0,
            scratchOff: 0,
            houseAccounts: 0,
            moneyOrder: 0,
            refunds: 0,
            pluNotFound: 0,
            driveOff: 0,
            salesTax: 0,
            cashDeposit: 0,
            total: 0,
            actualDeposit: 0,
            insideTotal: 0,
            shortOver: 0,
            notes: '',
            storeId: selectedStore,
        },

        validationSchema: Yup.object({
            invoiceDate: Yup.date().required('Date is Required').typeError('Invalid Date format'),
        }),

        onSubmit: async (values) => {
            try {
                assignFloatValues(values);

                if (isEditMode) {
                    await DailySalesService.updateDailySalesService(values.dailySalesId, values)
                        .then(response => {
                            if (response?.isSucess) {
                                navigate('/salesinfo/dailysales', { state: { pageNumber: pageNumber } });
                            }
                        });
                } else {
                    await DailySalesService.createDailySalesService(values)
                        .then(response => {
                            if (response?.isSucess) {
                                if (isInWizard) {
                                    onNext();
                                }
                                else {
                                    navigate('/salesinfo/dailysales', { state: { pageNumber: pageNumber } });
                                }
                            }
                        });
                }
            } catch (error) {
                handleNotify('An error occurred while saving the daily sales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    const assignFloatValues = (values) => {

        values.fuel = parseFloat(values.fuel) || 0;
        values.credit = parseFloat(values.credit) || 0;
        values.cigarette = parseFloat(values.cigarette) || 0;
        values.debit = parseFloat(values.debit) || 0;
        values.beer = parseFloat(values.beer) || 0;
        values.fuelman = parseFloat(values.fuelman) || 0;
        values.taxGrocery = parseFloat(values.taxGrocery) || 0;
        values.taxGrocery = parseFloat(values.inHouse) || 0;
        values.check = parseFloat(values.check) || 0;
        values.noTax = parseFloat(values.noTax) || 0;
        values.ebt = parseFloat(values.ebt) || 0;
        values.deli = parseFloat(values.deli) || 0;
        values.lottoPay = parseFloat(values.lottoPay) || 0;
        values.phoneCards = parseFloat(values.phoneCards) || 0;
        values.payOut = parseFloat(values.payOut) || 0;
        values.lotto = parseFloat(values.lotto) || 0;
        values.coupons = parseFloat(values.coupons) || 0;
        values.scratchOff = parseFloat(values.scratchOff) || 0;
        values.houseAccounts = parseFloat(values.houseAccounts) || 0;
        values.moneyOrder = parseFloat(values.moneyOrder) || 0;
        values.refunds = parseFloat(values.refunds) || 0;
        values.pluNotFound = parseFloat(values.pluNotFound) || 0;
        values.driveOff = parseFloat(values.driveOff) || 0;
        values.salesTax = parseFloat(values.salesTax) || 0;
        values.cashDeposit = parseFloat(values.cashDeposit) || 0;
        values.total = parseFloat(values.total) || 0;
        values.actualDeposit = parseFloat(values.actualDeposit) || 0;
        values.insideTotal = parseFloat(values.insideTotal) || 0;
        values.shortOver = parseFloat(values.shortOver) || 0;
    };


    const handleCancelClick = () => {
        navigate('/salesinfo/dailysales', { state: { pageNumber: pageNumber } });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);

        const updatedValues = { ...formik.values, [name]: value };

        const calculateTotal = () => {
            return (
                parseFloat(updatedValues.fuel || 0) +
                parseFloat(updatedValues.cigarette || 0) +
                parseFloat(updatedValues.beer || 0) +
                parseFloat(updatedValues.taxGrocery || 0) +
                parseFloat(updatedValues.inHouse || 0) +
                parseFloat(updatedValues.noTax || 0) +
                parseFloat(updatedValues.deli || 0) +
                parseFloat(updatedValues.phoneCards || 0) +
                parseFloat(updatedValues.lotto || 0) +
                parseFloat(updatedValues.scratchOff || 0) +
                parseFloat(updatedValues.moneyOrder || 0) +
                parseFloat(updatedValues.pluNotFound || 0) +
                parseFloat(updatedValues.salesTax || 0)
            );
        };

        const calculateCashDeposit = () => {
            return (
                calculateTotal() - (
                    parseFloat(updatedValues.inHouse || 0) +
                    parseFloat(updatedValues.credit || 0) +
                    parseFloat(updatedValues.debit || 0) +
                    parseFloat(updatedValues.fuelman || 0) +
                    parseFloat(updatedValues.check || 0) +
                    parseFloat(updatedValues.ebt || 0) +
                    parseFloat(updatedValues.lottoPay || 0) +
                    parseFloat(updatedValues.payOut || 0) +
                    parseFloat(updatedValues.coupons || 0) +
                    parseFloat(updatedValues.houseAccounts || 0) +
                    parseFloat(updatedValues.refunds || 0) +
                    parseFloat(updatedValues.driveOff || 0)
                )
            );
        };


        const calculateInsideTotal = () => {
            return (
                parseFloat(updatedValues.beer || 0) +
                parseFloat(updatedValues.cigarette || 0) +
                parseFloat(updatedValues.taxGrocery || 0) +
                //parseFloat(updatedValues.inHouse || 0) +
                parseFloat(updatedValues.noTax || 0) +
                parseFloat(updatedValues.deli || 0) +
                parseFloat(updatedValues.phoneCards || 0) +
                parseFloat(updatedValues.pluNotFound || 0)
            );
        };

        const calculateShortOver = () => {
            const actualDeposit = parseFloat(updatedValues.actualDeposit || 0);
            const cashDeposit = calculateCashDeposit();
            return actualDeposit - cashDeposit;
        };

        formik.setFieldValue('total', calculateTotal());
        formik.setFieldValue('cashDeposit', calculateCashDeposit());
        formik.setFieldValue('insideTotal', calculateInsideTotal());
        formik.setFieldValue('shortOver', calculateShortOver());
    };
    return (
        <AuthGuard page="allowDailySales">
            <div className={showWrapper ? "page-wrapper" : ''}>
                <div className="page-content">
                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Daily Sales</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {item ? 'Edit Daily Sales' : 'Add Daily Sales'}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Daily Sales' : 'Add Daily Sales'}</div>
                            </div>
                        </>
                    )}
                    {!isInWizard && (<hr />)}

                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <div className="card">
                                <div className="card-body p-4">
                                    <form className=" row g-3 needs-validation" onSubmit={formik.handleSubmit} noValidate>
                                        <div className="col-md-12">
                                            <div className="row g-3">
                                                <div className="d-flex col-md-12">
                                                    <div className="col-md-3">
                                                        <label htmlFor="invoiceDate" className="form-label">Date:<span className="text-danger">*</span></label>
                                                        {/* <input
                                                                type="date"
                                                                name="invoiceDate"
                                                                value={formik.values.invoiceDate}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                                                id="invoiceDate"
                                                                placeholder="Date"
                                                                required
                                                            /> */}
                                                        <DatePicker
                                                            selected={formik.values.invoiceDate}
                                                            onChange={val => {
                                                                formik.setFieldValue("invoiceDate", val);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            name="invoiceDate"
                                                            dateFormat="MM/dd/YYYY"
                                                            className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                                            id="invoiceDate"
                                                        />
                                                        {formik.touched.invoiceDate && formik.errors.invoiceDate ? (
                                                            <div className='text-danger'>{formik.errors.invoiceDate}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="fuel" className="form-label">Fuel:</label>
                                                    <input
                                                        name="fuel"
                                                        type="number"
                                                        value={formik.values.fuel}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="fuel"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="credit" className="form-label">Credit:</label>
                                                    <input
                                                        name="credit"
                                                        type="number"
                                                        value={formik.values.credit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="credit"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="cigarette" className="form-label">Cigarette:</label>
                                                    <input
                                                        name="cigarette"
                                                        type="number"
                                                        value={formik.values.cigarette}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="cigarette"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="debit" className="form-label">Debit:</label>
                                                    <input
                                                        name="debit"
                                                        type="number"
                                                        value={formik.values.debit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="debit"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="beer" className="form-label">Beer:</label>
                                                    <input
                                                        name="beer"
                                                        type="number"
                                                        value={formik.values.beer}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="beer"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="fuelman" className="form-label">Fuelman:</label>
                                                    <input
                                                        name="fuelman"
                                                        type="number"
                                                        value={formik.values.fuelman}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="fuelman"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="taxGrocery" className="form-label">Tax Grocery:</label>
                                                    <input
                                                        name="taxGrocery"
                                                        type="number"
                                                        value={formik.values.taxGrocery}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="taxGrocery"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="inHouse" className="form-label">Inhouse Credit/Debit</label>
                                                    <input
                                                        name="inHouse"
                                                        type="number"
                                                        value={formik.values.inHouse}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="inHouse"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="check" className="form-label">Check:</label>
                                                    <input
                                                        name="check"
                                                        type="number"
                                                        value={formik.values.check}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="check"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="noTax" className="form-label">No Tax:</label>
                                                    <input
                                                        name="noTax"
                                                        type="number"
                                                        value={formik.values.noTax}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="noTax"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="ebt" className="form-label">EBT:</label>
                                                    <input
                                                        name="ebt"
                                                        type="number"
                                                        value={formik.values.ebt}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="ebt"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="deli" className="form-label">Deli:</label>
                                                    <input
                                                        name="deli"
                                                        type="number"
                                                        value={formik.values.deli}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="deli"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="lottoPay" className="form-label">Lotto Pay:</label>
                                                    <input
                                                        name="lottoPay"
                                                        type="number"
                                                        value={formik.values.lottoPay}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="lottoPay"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="phoneCards" className="form-label">Phone Cards:</label>
                                                    <input
                                                        name="phoneCards"
                                                        type="number"
                                                        value={formik.values.phoneCards}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="phoneCards"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="payOut" className="form-label">Pay Out:</label>
                                                    <input
                                                        name="payOut"
                                                        type="number"
                                                        value={formik.values.payOut}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="payOut"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="lotto" className="form-label">Lotto:</label>
                                                    <input
                                                        name="lotto"
                                                        type="number"
                                                        value={formik.values.lotto}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="lotto"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="coupons" className="form-label">Coupans:</label>
                                                    <input
                                                        name="coupons"
                                                        type="number"
                                                        value={formik.values.coupons}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="coupons"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="scratchOff" className="form-label">Scratch Off:</label>
                                                    <input
                                                        name="scratchOff"
                                                        type="number"
                                                        value={formik.values.scratchOff}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="scratchOff"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="houseAccounts" className="form-label">House Accounts:</label>
                                                    <input
                                                        name="houseAccounts"
                                                        type="number"
                                                        value={formik.values.houseAccounts}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="houseAccounts"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="moneyOrder" className="form-label">Money Order:</label>
                                                    <input
                                                        name="moneyOrder"
                                                        type="number"
                                                        value={formik.values.moneyOrder}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="moneyOrder"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="refunds" className="form-label">Refunds:</label>
                                                    <input
                                                        name="refunds"
                                                        type="number"
                                                        value={formik.values.refunds}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="refunds"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="pluNotFound" className="form-label">Plu Not Found:</label>
                                                    <input
                                                        name="pluNotFound"
                                                        type="number"
                                                        value={formik.values.pluNotFound}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="pluNotFound"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="driveOff" className="form-label">Drive Off:</label>
                                                    <input
                                                        name="driveOff"
                                                        type="number"
                                                        value={formik.values.driveOff}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="driveOff"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="salesTax" className="form-label">Sales Tax:</label>
                                                    <input
                                                        name="salesTax"
                                                        type="number"
                                                        value={formik.values.salesTax}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="salesTax"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="cashDeposit" className="form-label">Cash Deposit:</label>
                                                    <input
                                                        name="cashDeposit"
                                                        type="number"
                                                        value={formik.values.cashDeposit}
                                                        readOnly
                                                        className="form-control"
                                                        id="cashDeposit"
                                                    />
                                                </div>

                                                {/* total, Cash Deposit, inside total, short Over */}
                                                <div className="col-md-6">
                                                    <label htmlFor="total" className="form-label">Total:</label>
                                                    <input
                                                        name="total"
                                                        type="number"
                                                        value={formik.values.total}
                                                        readOnly
                                                        className="form-control"
                                                        id="total"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="actualDeposit" className="form-label">Actual Deposit:</label>
                                                    <input
                                                        name="actualDeposit"
                                                        type="number"
                                                        value={formik.values.actualDeposit}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="actualDeposit"
                                                        placeholder="0"
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="insideTotal" className="form-label">Inside Total:</label>
                                                    <input
                                                        name="insideTotal"
                                                        type="number"
                                                        value={formik.values.insideTotal}
                                                        readOnly
                                                        className="form-control"
                                                        id="insideTotal"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="shortOver" className="form-label">Over/Short Total:</label>
                                                    <input
                                                        name="shortOver"
                                                        type="number"
                                                        value={formik.values.shortOver}
                                                        readOnly
                                                        className="form-control"
                                                        id="shortOver"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="notes" className="form-label">Notes:</label>
                                                    <textarea
                                                        name="notes"
                                                        value={formik.values.notes}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id="notes"
                                                        rows="3"
                                                        placeholder="Additional notes"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-5">
                                            <div className="d-md-flex d-grid align-items-center gap-3">
                                                {showButtons && (
                                                    <>
                                                        {isInWizard ? (
                                                            <>
                                                                {/* <button type="submit" className="btn btn-primary me-2" onNext={isInWizard ? onNext : navigate('/salesinfo/dailysales')}>Save&Next</button> */}
                                                                <button type="submit" className="btn btn-primary me-2">Save & Next</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>Skip</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={() => navigate('/dashboard')}>Finish</button>
                                                            </>
                                                        ) : (
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-primary px-4 me-3" onClick={onNext}>
                                                                    {item ? 'Update' : 'Save'}
                                                                </button>
                                                                <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthGuard>
    );
};

export default AddDailySalesComponent;