import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FooterComponent } from './footer.component';
import AuthGuard from '../authguard.component';
import DashboardHeaderComponent from './dashboard-header.component';
import LeftmenuComponent from './LeftmenuComponent';

const Layout = () => {


  return (
    <>
   <AuthGuard>
      <LeftmenuComponent />
      <DashboardHeaderComponent />
      <Outlet /> {/* This will render the matched child route */}
      {/* <FooterComponent /> */}
    </AuthGuard>
    </>
  );
};

export default Layout;
