import React, { useEffect } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { FuelSalesService } from "../../../services/user/fuelsales.services";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../common/notification/toaster_notify.component";
import AuthGuard from "../../common/authguard.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddNewFuelSalesComponent = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageNumber = location.state?.pageNumber || 0;
    // const fuelSalesData = location.state?.fuelSalesData || {};
    const fuelSalesId = location.state?.fuelSalesId || 0;
    const isEditMode = !!fuelSalesId || 0;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);



    const formik = useFormik({
        initialValues: {
            fuelSalesId: 0,
            invoiceDate: moment(new Date()).format('MM/DD/YYYY'),
            unleadedGallons: 0,
            plusGallons: 0,
            premiumGallons: 0,
            marineGallons: 0,
            dieselGallons: 0,
            unleadedSales: 0,
            plusSales: 0,
            premiumSales: 0,
            marineSales: 0,
            dieselSales: 0,
            totalGallons: 0,
            totalSales: 0,
            storeId: selectedStore
        },

        validationSchema: Yup.object({
            invoiceDate: Yup.date().required('Date is required').typeError('Invalid Date Format'),
        }),

        onSubmit: async (values) => {
            try {
                assignFloatValues(values);

                if (isEditMode) {
                    await FuelSalesService.updateFuelSale(fuelSalesId, values).then(response => {
                        if (response?.isSucess) {
                            navigate('/salesinfo/fuelsales', { state: { pageNumber: pageNumber } });
                        }
                    })
                } else {
                    await FuelSalesService.createFuelSale(values).then(response => {
                        if (response?.isSucess) {
                            if (isInWizard) {
                                onNext();
                            }
                            else {
                                navigate('/salesinfo/fuelsales', { state: { pageNumber: pageNumber } });
                            }
                        }
                    });
                }
            } catch (error) {
                handleNotify('An error occurred while saving the fuel sales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    function assignFloatValues(values) {
        values.unleadedGallons = parseFloat(values.unleadedGallons) || 0;
        values.plusGallons = parseFloat(values.plusGallons) || 0;
        values.premiumGallons = parseFloat(values.premiumGallons) || 0;
        values.marineGallons = parseFloat(values.marineGallons) || 0;
        values.unleadedSales = parseFloat(values.unleadedSales) || 0;
        values.plusSales = parseFloat(values.plusSales) || 0;
        values.dieselGallons = parseFloat(values.dieselGallons) || 0;
        values.premiumSales = parseFloat(values.premiumSales) || 0;
        values.marineSales = parseFloat(values.marineSales) || 0;
        values.dieselSales = parseFloat(values.dieselSales) || 0;
        values.totalGallons = parseFloat(values.totalGallons) || 0;
        values.totalSales = parseFloat(values.totalSales) || 0;
    };

    useEffect(() => {
        if (isEditMode) {
            FuelSalesService.getFuelSaleById(fuelSalesId)
                .then(response => {
                    formik.setValues({
                        ...response,
                        invoiceDate: moment(response?.invoiceDate).format('YYYY-MM-DD'),
                        marineGallons: response.marineGallons || '0.00',
                        marineSales: response.marineSales || '0.00'
                    })
                })
        }
    }, []);


    const calculateTotal = (values) => {
        const { unleadedGallons, plusGallons, premiumGallons, marineGallons, dieselGallons, unleadedSales, plusSales, premiumSales, marineSales, dieselSales } = values;

        // Calculate total gallons
        const totalGallons = (
            (parseFloat(unleadedGallons) || 0) +
            (parseFloat(plusGallons) || 0) +
            (parseFloat(premiumGallons) || 0) +
            (parseFloat(marineGallons) || 0) +
            (parseFloat(dieselGallons) || 0)
        ).toFixed(2);

        // Calculate total sales
        const totalSales = (
            (parseFloat(unleadedSales) || 0) +
            (parseFloat(plusSales) || 0) +
            (parseFloat(premiumSales) || 0) +
            (parseFloat(marineSales) || 0) +
            (parseFloat(dieselSales) || 0)
        ).toFixed(2);

        return { totalGallons, totalSales };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        formik.setFieldValue(name, value);

        const newTotals = calculateTotal({
            ...formik.values,
            [name]: value
        });

        formik.setFieldValue('totalGallons', newTotals.totalGallons);
        formik.setFieldValue('totalSales', newTotals.totalSales);
    };

    const handleCancelClick = () => {
        navigate('/salesinfo/fuelsales', { state: { pageNumber: pageNumber } });
    };

    return (
        <AuthGuard page={"allowFuelSales"}>
            <div className={showWrapper ? "page-wrapper" : ''}>
                <div className="page-content">
                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Fuel Sales</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {isEditMode ? 'Edit Fuel Sales' : 'Add Fuel Sales'}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Fuel Sales' : 'Add Fuel Sales'}</div>
                            </div>
                        </>
                    )}
                    {!isInWizard && (<hr />)}

                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <div className="card">
                                <div className="card-body p-4">
                                    <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
                                        <div className="col-md-3 col-4" >
                                            <label htmlFor="invoiceDate" className="form-label">Date: <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-3 col-7">
                                            <DatePicker
                                                selected={formik.values.
                                                    invoiceDate ? new Date(formik.
                                                        values.invoiceDate) : null}
                                                onChange={val => formik.
                                                    setFieldValue("invoiceDate",
                                                        val ? moment(val).format('MM/DD/YYYY') : '')}
                                                onBlur={formik.handleBlur}
                                                name="invoiceDate"
                                                dateFormat="MM/dd/yyyy"
                                                className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                                id="invoiceDate"
                                            />
                                            {formik.touched.invoiceDate && formik.errors.invoiceDate && (
                                                <div className='text-danger'>{formik.errors.invoiceDate}</div>
                                            )}
                                        </div>


                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4"></div>
                                            <div className="col-md-3 col-4"><strong>Gallons</strong></div>
                                            <div className="col-md-3 col-4"><strong>Sales</strong></div>
                                        </div>

                                        {['unleaded', 'plus', 'premium', 'marine', 'diesel'].map(fuelType => (
                                            <div className="d-flex gap-1" key={fuelType}>
                                                <div className="col-md-3 col-4">
                                                    <label className="form-label">
                                                        {fuelType.charAt(0).toUpperCase() + fuelType.slice(1)}
                                                    </label>
                                                </div>
                                                <div className="col-md-3 col-4">
                                                    <input
                                                        type="number"
                                                        name={`${fuelType}Gallons`}
                                                        value={formik.values[`${fuelType}Gallons`]}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className={`form-control ${formik.touched[`${fuelType}Gallons`] && formik.errors[`${fuelType}Gallons`] ? 'is-invalid' : ''}`}
                                                        id={`${fuelType}Gallons`}
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <div className="col-md-3 col-4">
                                                    <input
                                                        type="number"
                                                        name={`${fuelType}Sales`}
                                                        value={formik.values[`${fuelType}Sales`]}
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className={`form-control ${formik.touched[`${fuelType}Sales`] && formik.errors[`${fuelType}Sales`] ? 'is-invalid' : ''}`}
                                                        id={`${fuelType}Sales`}
                                                        placeholder="0"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="d-flex gap-1">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="totalGallons" className="form-label">Total </label>
                                            </div>
                                            <div className="col-md-3 col-4">
                                                <input
                                                    type="number"
                                                    value={formik.values.totalGallons}
                                                    className="form-control"
                                                    id="totalGallons"
                                                    placeholder="0"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-3 col-4">
                                                <input
                                                    type="number"
                                                    value={formik.values.totalSales}
                                                    className="form-control"
                                                    id="totalSales"
                                                    placeholder="0"
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-5">
                                            <div className="d-md-flex d-grid align-items-center gap-3">
                                                {showButtons && (
                                                    <>
                                                        {isInWizard ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary me-2">Save & Next</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onBack}>
                                                                    Back
                                                                </button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>
                                                                    Skip
                                                                </button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={()=>navigate('/dashboard')}>
                                                                    Finish
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-primary px-4 me-3" onClick={onNext}>
                                                                    {isEditMode ? 'Update' : 'Save'}
                                                                </button>
                                                                <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthGuard>
    );
};

export default AddNewFuelSalesComponent;
