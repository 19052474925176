import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import DataTable from "react-data-table-component";
import { LiquorStoreSalesService } from "../../../services/user/liquorstoresales.services";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
//import AuthGuard from "../../common/authguard.component";

const LiquorStoreSalesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filterText, setFilterText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await LiquorStoreSalesService.getLiquorStoreService(selectedStore);
                setData(response);
            } catch (error) {
                console.error('Error fetching Liquor Store Data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedStore]);


    const columns = [
    
        { width: '9%', name: 'Tabacco', selector: row => row.tobacco, sortable: true },
        { width: '9%', name: 'Beer', selector: row => row.beer, sortable: true },
        { width: '9%', name: 'Spirits', selector: row => row.spirits, sortable: true },
        { width: '9%', name: 'Wine', selector: row => row.wine, sortable: true },
        { width: '10%', name: 'Non-Alcoholic', selector: row => row.nonAlcoholic, sortable: true },
        { width: '10%', name: 'BAR Sales', selector: row => row.barSales || '0.00', sortable: true },
        { width: '10%', name: 'Pay Out', selector: row => row.payOut, sortable: true },
        { width: '10%', name: 'TotalSalesTax', selector: row => row.totalSalesTax, sortable: true },
        // { width: '10%', name: 'Actual Deposit', selector: row => row.actualDeposit, sortable: true },
        // { width: '9%', name: 'Coupons', selector: row => row.coupons, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEditClick(row)}
                    >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)}
                    >
                        <span className="d-none d-md-block">Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];

    const handleSearch = (event) => {
        setFilterText(event.target.value);
    };

    const handleAddNewClick = () => {
        navigate('/salesinfo/liquorstoresales/create', { state: { pageNumber: currentPage } });
    };

    const handleEditClick = (item) => {
        navigate('/salesinfo/liquorstoresales/edit', { state: { liquorSalesId: item?.liquorSalesId, pageNumber: currentPage } });
    };

    const handleDeleteClick = (item) => {
        setShowModal(true);
        setItemToDelete(item);
    };

    //delete many
    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.liquorSalesId.toString())
                : itemToDelete ? [itemToDelete.liquorSalesId.toString()] : [];

            if (idsToDelete.length > 0) {
                console.log('IDs to delete:', idsToDelete);
                await LiquorStoreSalesService.deleteManyLiquorStoreService(idsToDelete);
                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.liquorSalesId.toString())));
                setSelectedRows([]);
                setItemToDelete(null);
                setShowModal(false);
            } else {
                console.warn('No valid IDs to delete.');
            }
        } catch (error) {
            console.error('Error while deleting:', error);
            handleNotify(error.response?.data?.message || error.message || 'Error while deleting liquorSales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };


    const handleModalClose = () => setShowModal(false);

    const filteredData = data.filter(item =>
        Object.keys(item).some(key =>
            item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    if (loading) return <div>Loading...</div>;


    return (
        <>

            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Sales Info</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active" aria-current="page">Liquor Store Sales</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                        <div className="breadcrumb-item pe-3 ">Sales Info &gt; Liquor Store Sales</div>
                    </div>
                    <hr />
                    <div className="bg-white col-md-12 p-3">
                        <div className="d-md-flex d-grid align-items-center gap-3 mb-5">
                            <div className="d-flex ">
                                <button
                                    type="button"
                                    className="btn btn-primary px-4 mt-3 me-3"
                                    onClick={handleAddNewClick}
                                >
                                    New
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                    onClick={handleDeleteSelectedRowClick}
                                >
                                    Delete
                                </button>
                            </div>
                            <div className="mt-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search..."
                                    value={filterText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        {filteredData &&
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                selectableRows
                                onChangePage={onChangePage}
                                onSelectedRowsChange={handleRowSelected}
                                paginationDefaultPage={currentPage}
                            />
                        }
                    </div>

                </div>
            </div>
            {showModal &&
                <DeleteModelPopupComponent
                    show={showModal}
                    onClose={handleModalClose}
                    onConfirm={handleDeleteConfirm}
                    title="Confirm"
                    confirmText="Delete"
                    Message="Are you sure want to delete selected record(s)?"
                />
            }

        </>
    )
}

export default LiquorStoreSalesComponent;