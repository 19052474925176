import React, { useState, useEffect, useCallback } from "react";
import Stepper from 'bs-stepper';
import AddDailySalesComponent from "../sales-info/addDailySales.component";
import AddNewCreditCardComponent from "../sales-info/addNewCreditCard.component";
import AddNewFuelSalesComponent from "../sales-info/addNewFuelSales.component";
import AddNewRebatesAndCommission from "../sales-info/addNewRebates.component";
import AddNewFuelDeliveryComponent from "../purchase-info/addNewFuelDelivery.component";
import AddExpensesComponent from "../payment/add-expenses.component";
import AddPaymentComponent from "../payment/add-payments.component";
import WizardSteps from "./wizard-steps.component";
import { shallowEqual, useSelector } from "react-redux";
import { getLeftMenuAccess } from "../../../utility";
import AuthGuard from "../../common/authguard.component";
import { ALL_STEPS } from "../../common/constantkey";
import AddLiquorStoreSalesComponent from "../sales-info/addliquorstoresales.component";

const DailyWizardsComponent = () => {
    const [currentStepIndex, setCurrentStepIndex] = useState(parseInt(localStorage) || 1);
    //const [stepperInstance, setStepperInstance] = useState(null);
    const user = useSelector(({ user }) => user, shallowEqual);
    const stores = user.stores;
    const storeId = user.selectedStoreId;
    const selectedStore = stores?.find(store => store.storeId === storeId);
    const dailyWizardAccess = getLeftMenuAccess(selectedStore);
    const accessibleSteps = ALL_STEPS.filter(step => dailyWizardAccess[step.permission]);

    useEffect(() => {
        localStorage.setItem('activeStep' || 1, currentStepIndex)
    }, [selectedStore, currentStepIndex])

    const onCurrentStepClick = (key) => {
        setCurrentStepIndex(key)
    };

    const handleNext = () => {
        setCurrentStepIndex(prevStepIndex => {
            const nextStepIndex = prevStepIndex + 1;
            return nextStepIndex <= ALL_STEPS.length ? nextStepIndex : prevStepIndex;
        });
    };

    const handlePrevious = () => {
        setCurrentStepIndex(prevStepIndex => {
            const prevStepIndexValue = prevStepIndex - 1;
            return prevStepIndexValue >= 1 ? prevStepIndexValue : prevStepIndex;
        })
    };

    return (
        <>
            <AuthGuard>
                <div className="page-wrapper">
                    <div className="page-content" >
                        <div>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Daily Wizards</div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">Daily Wizards</div>
                            </div>
                            <hr />
                            {stores.length > 0 &&
                                <div id="stepper1" className="bs-stepper">
                                    <div className="card">
                                        <WizardSteps currentStepIndex={currentStepIndex} steps={accessibleSteps} onCurrentStepClick={onCurrentStepClick} stores={stores} />
                                        <div className="card-body">
                                            <div className="bs-stepper-content">
                                                {accessibleSteps.map((step) => (
                                                    <div key={step.id} id={step.targetId} role="tabpanel" className={`bs-stepper-pane ${currentStepIndex === step.id ? 'active' : ''}`}>
                                                        {step.id === 1 && step.id === currentStepIndex && (
                                                            <AddDailySalesComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onNext={handleNext}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 2 && step.id == currentStepIndex && (
                                                            <AddLiquorStoreSalesComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onNext={handleNext}
                                                                onBack={handlePrevious}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 3 && step.id === currentStepIndex && (
                                                            <AddNewCreditCardComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onBack={handlePrevious}
                                                                onNext={handleNext}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 4 && step.id === currentStepIndex && (
                                                            <AddNewFuelSalesComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onBack={handlePrevious}
                                                                onNext={handleNext}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 5 && step.id === currentStepIndex && (
                                                            <AddNewRebatesAndCommission
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onNext={handleNext}
                                                                onBack={handlePrevious}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 6 && step.id === currentStepIndex && (
                                                            <AddNewFuelDeliveryComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onNext={handleNext}
                                                                onBack={handlePrevious}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 7 && step.id === currentStepIndex && (
                                                            <AddPaymentComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onNext={handleNext}
                                                                onBack={handlePrevious}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}
                                                        {step.id === 8 && step.id == currentStepIndex && (
                                                            <AddExpensesComponent
                                                                //isInWizard={true}
                                                                isInWizard={true ? 'active-class' : 'inactive-class'}
                                                                onNext={handleNext}
                                                                onBack={handlePrevious}
                                                                showBreadcrumbs={false}
                                                                showButtons={true}
                                                                showWrapper={false}
                                                            />
                                                        )}

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </AuthGuard>
        </>
    )
}

export default DailyWizardsComponent;