import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getLeftMenuAccess, getLocalStorageItem } from "../../../utility";
import { ALL_STEPS, LeftMenuKeys } from "../constantkey";
import $ from 'jquery';
import { logoutUser } from "../../../redux/user/userslice";

const LeftmenuComponent = () => {
    const user = useSelector(({ user }) => user, shallowEqual);
    const storeId = user.selectedStoreId;
    const stores = user.stores;
    const selectedStore = stores?.find(store => store.storeId === storeId);
    const leftMenuAccess = getLeftMenuAccess(selectedStore) || {};
    const [openDropdown, setOpenDropdown] = useState(localStorage.getItem('selectedMenu') || null);
    const [selectedChildMenu, setSelectedChildMenu] = useState(localStorage.getItem('selectedChildMenu') || null);
    // const [isManualToggle, setIsManualToggle] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const toggleSidebar = () => {
    //     setIsManualToggle(!isManualToggle);
    //     const wrapper = document.querySelector('.wrapper');
    //     if (wrapper) {
    //         wrapper.classList.toggle('toggled');
    //     }
    // };

    const toggleDropdownList = (key) => {

        const newKey = openDropdown === key ? null : key;
        setOpenDropdown(newKey);
        localStorage.setItem('selectedMenu', newKey);

        if (key === LeftMenuKeys.AccountSetup) {
            navigate('/accountsetup/myprofile')
            setSelectedChildMenu(LeftMenuKeys.MyProfile);
            localStorage.setItem('selectedChildMenu', LeftMenuKeys.MyProfile);
        }
        else if (key === LeftMenuKeys.Reports) {
            navigate('/reports/generalreports')
            setSelectedChildMenu(LeftMenuKeys.GeneralReports);
            localStorage.setItem('selectedChildMenu', LeftMenuKeys.GeneralReports);
        } else if (key === LeftMenuKeys.MoneyCounter) {
            navigate('/moneycounter');
            setSelectedChildMenu(LeftMenuKeys.MoneyCounter);
            localStorage.setItem('selectedChildMenu', LeftMenuKeys.MoneyCounter);
        }

        const dailyWizardAccess = getLeftMenuAccess(selectedStore);
        const nextStep = ALL_STEPS.filter(step => dailyWizardAccess[step.permission] && step.parent === key);

        if (nextStep.length > 0) {
            navigate(nextStep[0].route)
            setSelectedChildMenu(nextStep[0].key);
            localStorage.setItem('selectedChildMenu', nextStep[0].key);
        }

        const isExpireRefresh = getLocalStorageItem("isRefreshExp");
        if (isExpireRefresh) {
            dispatch(logoutUser());
            localStorage.clear();
            navigate('/login')
        }
    };

    const activeStyle = {
        // backgroundColor: '#d1d1ed',
        backgroundColor: '#DCDCDC',
        color: '#378805'
    };

    const onChildMenuClick = (key) => {

        // const newKey = openDropdown === key ? null : key;
        setSelectedChildMenu(key);
        localStorage.setItem('selectedChildMenu', key);
        // localStorage.setItem('selectedMenu', '');
        handleSidebarToggle('close');
        const isExpireRefresh = getLocalStorageItem("isRefreshExp");
        if (isExpireRefresh) {
            dispatch(logoutUser());
            localStorage.clear();
            navigate('/login')
        }
    }
    // Scrollbar styles for the sidebar
    useEffect(() => {
        $(".mobile-toggle-menu").on("click", function () {
            $(".wrapper").addClass("toggled")
        })

        $(".toggle-icon").on("click", function () {
            //$(".wrapper").removeClass("toggled")
            ///// $(".wrapper").toggleClass("toggled")
        });
        const style = document.createElement('style');
        style.innerHTML = `
            .sidebar-wrapper {
                position: relative;
            }
            .sidebar-wrapper:hover::-webkit-scrollbar {
                width: 8px;
            }
            .sidebar-wrapper:hover::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 4px;
            }
            .sidebar-wrapper::-webkit-scrollbar {
                width: 0;
            }
            .sidebar-wrapper::-webkit-scrollbar-thumb {
                background: transparent;
            }
            .sidebar-wrapper::-webkit-scrollbar-track {
                background: transparent;
            }
        `;
        document.head.appendChild(style);

        return () => {
            $(".mobile-toggle-menu").off("click");
            $(".toggle-icon").off("click");
            document.head.removeChild(style);
        };
    }, []);

    // Handle sidebar toggle
    const handleSidebarToggle = (action) => {
        const wrapper = document.querySelector('.wrapper');
        if (wrapper) {
            wrapper.classList.toggle('toggled', action === 'open');
        }
    };

    // const handleMouseEnter = () => {
    //     if (!isManualToggle) {
    //         handleSidebarToggle('open');
    //     }
    // };

    // const handleMouseLeave = () => {
    //     if (!isManualToggle) {
    //         handleSidebarToggle('close');
    //     }
    // };
    return (
        <div className="wrapper"
        // onMouseEnter={handleMouseLeave}
        // onMouseLeave={handleMouseEnter}
        >
            <div className="sidebar-wrapper bg-light overflow-auto" data-simplebar="true">
                <div className="sidebar-header">
                    <Link to="/dashboard">
                        <img src="/assets/DB2.png" alt="Logo" className="logo" style={{ width: '160px', height: 'auto', color: '#378805', filter: 'invert(50%) sepia(90%) hue-rotate(90deg) saturate(400%) brightness(70%)' }} />
                    </Link>
                    <div className="toggle-icon ms-auto d-md-none d-lg-none">

                        <i className="bx--dots-vertical-rounded" />
                    </div>

                    {/* <div className="toggle-icon ms-auto" onClick={toggleSidebar}>
                        <i className="bx bx-arrow-back" />
                    </div> */}
                </div>
                <ul className="metismenu" id="menu">
                    {stores.length > 0 && leftMenuAccess.allowDailySales && leftMenuAccess.allowCreditCards && leftMenuAccess.allowFuelSales && leftMenuAccess.allowRebates && leftMenuAccess.allowPayments && leftMenuAccess.allowExpenses && leftMenuAccess.allowLiquorStore && (
                        <li>
                            <Link to="/dailywizards"
                                onClick={() => onChildMenuClick(LeftMenuKeys.Wizard)}
                                style={selectedChildMenu === LeftMenuKeys.Wizard ? activeStyle : {}}
                            >
                                <div className="parent-icon">
                                    <i className="fadeIn animated bx bx-customize" />

                                </div>
                                <div className="menu-title flex-grow-1">Wizards</div>
                                <div>
                                    {/* <i className={`bx ${openDropdown === 'reports' ? 'bx-chevron-down' : 'bx-chevron-left'}`} style={{ fontSize: '24px' }}></i> */}
                                    <i style={{ fontSize: '24px' }}></i>
                                </div>
                            </Link>
                        </li>
                    )}
                    {leftMenuAccess.allowSalesTab && (
                        <li>
                            <a style={{
                                cursor: 'pointer',
                                ...((openDropdown === LeftMenuKeys.SalesInfo) ? activeStyle : {})
                            }} onClick={() => toggleDropdownList(LeftMenuKeys.SalesInfo)}>
                                <div className="parent-icon">
                                    <i className="lni lni-bar-chart"></i>
                                </div>
                                <div className="menu-title flex-grow-1">Sales Info</div>
                                <div>
                                    <i className={`bx ${openDropdown === LeftMenuKeys.SalesInfo ? 'bx-chevron-down' : 'bx-chevron-left'}`} style={{ fontSize: '24px' }}></i>
                                </div>
                            </a>
                            {openDropdown === LeftMenuKeys.SalesInfo && (
                                <ul>
                                    {leftMenuAccess.allowDailySales && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.DailySales)} style={selectedChildMenu == LeftMenuKeys.DailySales ? activeStyle : null} >
                                            <Link to="/salesinfo/dailysales" >
                                                <i className="bx bx-radio-circle" />
                                                Daily Sales
                                            </Link>
                                        </li>
                                    )}
                                    {leftMenuAccess.allowLiquorStore && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.LiquorStore)} style={selectedChildMenu == LeftMenuKeys.LiquorStore ? activeStyle : null} >
                                            <Link to="/salesinfo/liquorstoresales" >
                                                <i className="bx bx-radio-circle" />
                                                Liquor Store Sales
                                            </Link>
                                        </li>
                                    )}
                                    {leftMenuAccess.allowCreditCards && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.CreditCards)} style={selectedChildMenu == LeftMenuKeys.CreditCards ? activeStyle : null}>
                                            <Link to="/salesinfo/creditcards">
                                                <i className="bx bx-radio-circle" />
                                                Credit Cards
                                            </Link>
                                        </li>
                                    )}
                                    {leftMenuAccess.allowFuelSales && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.FuelSales)} style={selectedChildMenu == LeftMenuKeys.FuelSales ? activeStyle : null}>
                                            <Link to="/salesinfo/fuelsales">
                                                <i className="bx bx-radio-circle" />
                                                Fuel Sales
                                            </Link>
                                        </li>
                                    )}
                                    {leftMenuAccess.allowRebates && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.Rebates)} style={selectedChildMenu == LeftMenuKeys.Rebates ? activeStyle : null}>
                                            <Link to="/salesinfo/rebates">
                                                <i className="bx bx-radio-circle" />
                                                Rebates/Commission
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </li>
                    )}

                    {leftMenuAccess.allowPaymentTab && (
                        <li>
                            <a style={{
                                cursor: 'pointer',
                                ...((openDropdown === LeftMenuKeys.Payment) ? activeStyle : {})
                            }} onClick={() => toggleDropdownList(LeftMenuKeys.Payment)}>
                                <div className="parent-icon">
                                    <i className="lni lni-investment" />
                                </div>
                                <div className="menu-title flex-grow-1">Payment</div>
                                <div>
                                    <i className={`bx ${openDropdown === LeftMenuKeys.Payment ? 'bx-chevron-down' : 'bx-chevron-left'}`} style={{ fontSize: '24px' }}></i>
                                </div>
                            </a>
                            {openDropdown === LeftMenuKeys.Payment && (
                                <ul>
                                    {leftMenuAccess.allowPayments && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.Payment)} style={selectedChildMenu == LeftMenuKeys.Payment ? activeStyle : null}>
                                            <Link to="/payment/payments">
                                                <i className="bx bx-radio-circle" />
                                                Payments
                                            </Link>
                                        </li>
                                    )}
                                    {leftMenuAccess.allowExpenses && (
                                        <li onClick={() => onChildMenuClick(LeftMenuKeys.Expenses)} style={selectedChildMenu == LeftMenuKeys.Expenses ? activeStyle : null}>
                                            <Link to="/payment/expenses">
                                                <i className="bx bx-radio-circle" />
                                                Expenses
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </li>
                    )}
                    {leftMenuAccess.allowFuelDelivery && (
                        <li>
                            <a style={{
                                cursor: 'pointer',
                                ...((openDropdown === LeftMenuKeys.PurchaseInfo) ? activeStyle : {})
                            }} onClick={() => toggleDropdownList(LeftMenuKeys.PurchaseInfo)}>
                                <div className="parent-icon">
                                    <i className="lni lni-bar-chart"></i>
                                </div>
                                <div className="menu-title flex-grow-1">Purchase Info</div>
                                <div>
                                    <i className={`bx ${openDropdown === LeftMenuKeys.PurchaseInfo ? 'bx-chevron-down' : 'bx-chevron-left'}`} style={{ fontSize: '24px' }}></i>
                                </div>
                            </a>
                            {openDropdown === LeftMenuKeys.PurchaseInfo && (
                                <ul>
                                    <li onClick={() => onChildMenuClick(LeftMenuKeys.FuelDelivery)} style={selectedChildMenu == LeftMenuKeys.FuelDelivery ? activeStyle : null}>
                                        <Link to="/purchaseinfo/fueldelivery">
                                            <i className="bx bx-radio-circle" />
                                            Fuel Delivery
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    )}
                    <li>
                        <a style={{
                            cursor: 'pointer',
                            ...((openDropdown === LeftMenuKeys.Reports) ? activeStyle : {})
                        }} onClick={() => toggleDropdownList(LeftMenuKeys.Reports)}>
                            <div className="parent-icon">
                                <i className="fadeIn animated bx bx-file" />

                            </div>
                            <div className="menu-title flex-grow-1">Reports</div>
                            <div>
                                <i className={`bx ${openDropdown === LeftMenuKeys.Reports ? 'bx-chevron-down' : 'bx-chevron-left'}`} style={{ fontSize: '24px' }}></i>
                            </div>
                        </a>
                        {openDropdown === LeftMenuKeys.Reports && (
                            <ul>
                                <li onClick={() => onChildMenuClick(LeftMenuKeys.GeneralReports)} style={selectedChildMenu == LeftMenuKeys.GeneralReports ? activeStyle : null}>
                                    <Link to="/reports/generalreports">
                                        <i className="bx bx-radio-circle" />
                                        General Reports
                                    </Link>
                                </li>
                                {/* <li onClick={() => onChildMenuClick(LeftMenuKeys.ProfitLossReports)} style={selectedChildMenu == LeftMenuKeys.ProfitLossReports ? activeStyle : null}>
                                    <Link to="">
                                        <i className="bx bx-radio-circle" />
                                        Profit Loss Reports
                                    </Link>
                                </li> */}
                            </ul>
                        )}
                    </li>

                    <li>
                        <Link
                            to="/moneycounter"
                            style={{
                                cursor: 'pointer',
                                ...((selectedChildMenu === LeftMenuKeys.MoneyCounter) ? activeStyle : {})
                            }}
                            onClick={() => onChildMenuClick(LeftMenuKeys.MoneyCounter)}
                        >
                            <div className="parent-icon">
                                <i className="lni lni-calculator" />
                            </div>
                            <div className="menu-title flex-grow-1">Money Counter</div>
                        </Link>
                    </li>
                    <li>
                        <a style={{
                            cursor: 'pointer',
                            ...((openDropdown === LeftMenuKeys.AccountSetup) ? activeStyle : {})
                        }} onClick={() => toggleDropdownList(LeftMenuKeys.AccountSetup)}>
                            <div className="parent-icon">
                                <i className="lni lni-user" />
                            </div>
                            <div className="menu-title flex-grow-1">Account Setup</div>
                            <div>
                                <i className={`bx ${openDropdown === LeftMenuKeys.AccountSetup ? 'bx-chevron-down' : 'bx-chevron-left'}`} style={{ fontSize: '24px' }}></i>
                            </div>
                        </a>
                        {openDropdown === LeftMenuKeys.AccountSetup && (
                            <ul>
                                <li onClick={() => onChildMenuClick(LeftMenuKeys.MyProfile)} style={selectedChildMenu == LeftMenuKeys.MyProfile ? activeStyle : null}>
                                    <Link to="/accountsetup/myprofile">
                                        <i className="bx bx-radio-circle" />
                                        My Profile
                                    </Link>
                                </li>
                                <li onClick={() => onChildMenuClick(LeftMenuKeys.StoreMaster)} style={selectedChildMenu == LeftMenuKeys.StoreMaster ? activeStyle : null}>
                                    <Link to="/accountsetup/storemaster">
                                        <i className="bx bx-radio-circle" />
                                        Store Master
                                    </Link>
                                </li>
                                <li onClick={() => onChildMenuClick(LeftMenuKeys.VendorMaster)} style={selectedChildMenu == LeftMenuKeys.VendorMaster ? activeStyle : null}>
                                    <Link to="/accountsetup/vendormaster">
                                        <i className="bx bx-radio-circle" />
                                        Vendor Master
                                    </Link>
                                </li>
                                <li onClick={() => onChildMenuClick(LeftMenuKeys.CategoryMaster)} style={selectedChildMenu == LeftMenuKeys.CategoryMaster ? activeStyle : null}>
                                    <Link to='/accountsetup/categorymaster'>
                                        <i className="bx bx-radio-circle" />
                                        Category Master
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LeftmenuComponent;
