// var BASEURL= 'http://localhost:4000';  
// var BASEURL = 'https://amazing-bhaskara.180-179-213-167.plesk.page'
var BASEURL = '	https://busy-jackson.180-179-213-167.plesk.page'

export const EndPoint = {
    BASEURL: BASEURL,
    LOGIN: `${BASEURL}/user/login`,
    USER: `${BASEURL}/user`,
    STORE: `${BASEURL}/store`,
    VENDOR: `${BASEURL}/vendor`,
    BIND_VENDOR: `${BASEURL}/vendor/bindvendor`,
    DAILY_SALES: `${BASEURL}/dailysales`,

    LIQUOR_STORE: `${BASEURL}/liquorstoresales`,
    FETCH_LIQUOR_STORE: `${BASEURL}/liquorstoresales/fetchliquorsales`,
    
    FETCH_DAILY_SALES: `${BASEURL}/dailysales/fetchdailysales`,
    CREDIT_CARDS: `${BASEURL}/creditcard`,
    FUEL_SALES: `${BASEURL}/fuelsales`,
    REBATES_AND_COMMISSION:`${BASEURL}/rebates`,  
    FETCH_CREDIT_CARDS: `${BASEURL}/creditcard/fetchcreditcards`,
    FETCH_FUEL_SALES: `${BASEURL}/fuelsales/fetchfuelsales`,
    FETCH_REBATES_AND_COMMISSION: `${BASEURL}/rebates/fetchrebates`,
    FETCH_FUEL_DELIVERY: `${BASEURL}/fueldelivery/fetchfueldeliveries`,
    FUEL_DELIVERY: `${BASEURL}/fueldelivery`,
    FETCH_PAYMENTS: `${BASEURL}/payment/fetchpayment`,
    FETCH_Expense: `${BASEURL}/expenses/bindexpenses`,
    CATEGORY: `${BASEURL}/category`,
    BIND_CATEGORY: `${BASEURL}/category/bindcategory`,
    PAYMENT: `${BASEURL}/payment`,
    EXPENSES: `${BASEURL}/expenses`,
    REPORT: `${BASEURL}/report`,
    AUTH: `${BASEURL}/auth`,
}