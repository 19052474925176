import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScriptsComponent } from "./components/common/loadscripts.component";
import DashboardMainComponent from "./pages/dashboard.component";
import RegisterComponent from "./pages/user/register.component";
import Website from "./pages/website";
import NotFound from "./pages/notfound";
import StoreMasterComponent from "./components/dashboard/account-setup/store-master.component";
import AddStoreComponent from "./components/dashboard/account-setup/add-store.component";
import MyProfileComponent from "./components/dashboard/account-setup/myprofile.component";
import AddVendorComponent from "./components/dashboard/account-setup/add-vendor.component";
import AddDailySalesComponent from "./components/dashboard/sales-info/addDailySales.component";
import CreditCardsComponent from "./components/dashboard/sales-info/creditCards.component";
import AddNewCreditCardComponent from "./components/dashboard/sales-info/addNewCreditCard.component";
import FuelSalesComponent from "./components/dashboard/sales-info/fuelSales.component";
import AddNewFuelSalesComponent from "./components/dashboard/sales-info/addNewFuelSales.component";
import RebatesAndCommission from "./components/dashboard/sales-info/rebates.component";
import AddNewRebatesAndCommission from "./components/dashboard/sales-info/addNewRebates.component";
import PaymentsComponent from "./components/dashboard/payment/payments.component";
import ExpensesComponent from "./components/dashboard/payment/expenses.component";
import AddPaymentComponent from "./components/dashboard/payment/add-payments.component";
import FuelDeliveryComponent from "./components/dashboard/purchase-info/fuelDelivery.component";
import AddNewFuelDeliveryComponent from "./components/dashboard/purchase-info/addNewFuelDelivery.component";
import AddExpensesComponent from "./components/dashboard/payment/add-expenses.component";
import VendorMasterComponent from "./components/dashboard/account-setup/vendor-master.component";
import GeneralReportsComponent from "./components/dashboard/reports/generalreports.component";
import DailyWizardsComponent from "./components/dashboard/wizards/dailyWizards.component";
import DailySalesComponent from "./components/dashboard/sales-info/dailysales.component";
import CategoryMasterComponent from "./components/dashboard/category/category-master.component";
import CreateStore from "./pages/store/createstore";
import LoginComponent from "./pages/user/login.component";
import Layout from "./components/common/layout/layout";
import AddCategoryMasterComponent from "./components/dashboard/category/add-categorymaster.component";
import ScrollButton from './components/common/scrollbarbutton/scrollbar.component';
import MoneyCounter from './pages/moneycounter/moneycounter';
import LiquorStoreSalesComponent from './components/dashboard/sales-info/liquorstoresales.component';
import AddLiquorStoreSalesComponent from './components/dashboard/sales-info/addliquorstoresales.component';


function App() {
  return (
    <Router>
      <div className="App">
        <ScriptsComponent />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<DashboardMainComponent />} />
            <Route path="/dailywizards" element={<DailyWizardsComponent />} />

            <Route path="/salesinfo/dailysales" element={< DailySalesComponent />} />
            <Route path="/salesinfo/dailysales/create" element={<AddDailySalesComponent />} />
            <Route path="/salesinfo/dailysales/edit" element={<AddDailySalesComponent />} />

            <Route path="/salesinfo/liquorstoresales" element={<LiquorStoreSalesComponent/>} />
            <Route path="/salesinfo/liquorstoresales/create" element={<AddLiquorStoreSalesComponent />} />
            <Route path="/salesinfo/liquorstoresales/edit" element={<AddLiquorStoreSalesComponent />} />


            <Route path="/salesinfo/creditcards" element={<CreditCardsComponent />} />
            <Route path="/salesinfo/creditcards/create" element={<AddNewCreditCardComponent />} />
            <Route path="/salesinfo/creditcards/edit" element={<AddNewCreditCardComponent />} />

            <Route path="/salesinfo/fuelsales" element={<FuelSalesComponent />} />
            <Route path="/salesinfo/fuelsales/create" element={<AddNewFuelSalesComponent />} />
            <Route path="/salesinfo/fuelsales/edit" element={<AddNewFuelSalesComponent />} />

            <Route path="/salesinfo/rebates" element={<RebatesAndCommission />} />
            <Route path="/salesinfo/rebates/edit" element={< AddNewRebatesAndCommission />} />
            <Route path="/salesinfo/rebates/create" element={<AddNewRebatesAndCommission />} />

            <Route path="/payment/payments" element={<PaymentsComponent />} />
            <Route path="/payments/addpayments" element={<AddPaymentComponent />} />
            <Route path="/payments/editpayments" element={<AddPaymentComponent />} />

            <Route path="/payment/expenses" element={<ExpensesComponent />} />
            <Route path="/expenses/addexpenses" element={<AddExpensesComponent />} />
            <Route path="/expenses/editexpenses" element={<AddExpensesComponent />} />

            <Route path="/purchaseinfo/fueldelivery" element={<FuelDeliveryComponent />} />
            <Route path="/purchaseinfo/fueldelivery/create" element={<AddNewFuelDeliveryComponent />} />
            <Route path="/purchaseinfo/fueldelivery/edit" element={<AddNewFuelDeliveryComponent />} />

            <Route path="/reports/generalreports" element={<GeneralReportsComponent />} />

            <Route path="/moneycounter" element={<MoneyCounter />} />

            <Route path="/accountsetup/myprofile" element={<MyProfileComponent />} />

            <Route path="/accountsetup/storemaster" element={<StoreMasterComponent />} />
            <Route path="/storemaster/addstore" element={<AddStoreComponent />} />
            <Route path="/storemaster/editstore" element={<AddStoreComponent />} />

            <Route path="/accountsetup/vendormaster" element={<VendorMasterComponent />} />
            <Route path="/accountsetup/vendormaster/create" element={<AddVendorComponent />} />
            <Route path="/accountsetup/vendormaster/edit" element={<AddVendorComponent />} />

            <Route path="/accountsetup/categorymaster" element={<CategoryMasterComponent />} />
            <Route path="/categorymaster/addcategory" element={<AddCategoryMasterComponent />} />
            <Route path="/categorymaster/editcategory" element={<AddCategoryMasterComponent />} />

          </Route>
          <Route path="/404" element={<NotFound />} />
          <Route path="/" element={<Website />} />
          <Route path="/login" exact="/login" element={<LoginComponent />} />
          <Route path="/createstore" element={<CreateStore />} />
          <Route path="/register" element={<RegisterComponent />} />
        </Routes>
        <ScrollButton />
        <div className="lobibox-notify-wrapper bottom-right"></div>
      </div>
    </Router>
  );
}

export default App;
